/*APP.CSS*/

html {
	font-family: Arial !important;
}

a {
	text-decoration: none;
	color: black;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

//preprocessing begins here
#thisdoc #bodyId {
	.showTablet {
		display: none;
	}

	.expandIcon {
		cursor: pointer;
	}
	background: #fbfcfd;
	margin: 0;
	overflow-x: hidden;

	.expansionIcon {
		left: 92%;
	}
	.transparentFixedHeader {
		background-size: cover !important;
		display: flex;
		flex-direction: column;
		.MuiAppBar-colorPrimary {
			background: transparent;
			a {
				color: white;
			}
			.BarSelected {
				border-color: white;
			}
		}
		h1 {
			font-family: Red Hat Display;
			font-style: normal;
			font-weight: normal;
			font-size: 44px;
			color: #ffffff;
		}
		p {
			font-family: Inter;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 32px;
			color: #ffffff;
		}

		.imgGrid1 {
			margin-top: 100px;
			margin-left: -100px;
		}
		.textGrid1,
		.textGrid2 {
			max-width: 551px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;
			height: 90%;
			margin: auto;
			margin-top: -24px;
		}
		.textGrid3 {
			max-width: 551px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;
			margin: auto;
			margin-top: 0px;
		}
		.imgLimit {
			max-width: 744px;
		}
		.imgGrid2 {
			margin: auto;
		}
		.textGrid2 {
			h1 {
				color: #30333e;
				font-size: 36px;
			}
			p {
				color: #30333e;
			}
			p.alt {
				color: #73757a;
			}
		}
		.homeBtn,
		.homeBtn:hover {
			max-width: 213px;
			background: white;
			color: #5056e7;
			height: 48px;
			font-size: 16px;
		}
		.homeBtn.alt {
			background: #5056e7;
			color: white;
		}

		#thisdoc #bodyId .MuiBottomNavigation-root.footerFx {
			background: transparent;
			margin-top: -56px;
			filter: invert(1);
			filter: brightness(2.5);
		}
	}

	.Logo {
		padding-left: 40px;
		margin-right: 60px;
	}

	.MuiGrid-container {
		/*  height: 132px; */
	}
	.MuiTypography-root {
		color: #73757a;
	}
	.Typography-categories.MuiTypography-colorPrimary {
		color: #3f51b5 !important;
	}
	.Mui-disabled {
		color: #D3D3D3;
		//opacity: 0.5;
	}
	.MuiButton-containedPrimary {
		background-color: #5056e7;
		border-radius: 4px;
		text-transform: none;
	}
	.MuiExpansionPanel-root:before {
		background-color: white;
	}
	.MuiBottomNavigation-root {
		background-color: #fbfcfd;
	}
	.MuiExpansionPanel-root.Mui-expanded {
		margin: 0px 0;
		box-shadow: 0px 4px 24px rgba(6, 7, 23, 0.06);
		border-radius: 4px;
	}
	.MuiButton-containedPrimary:hover {
		background-color: #646af0;
	}
	.MuiAppBar-colorPrimary {
		color: black;
		background-color: #fff;
	}
	.MuiPaper-elevation1 {
		box-shadow: 0px 4px 16px rgba(6, 7, 23, 0.04);
	}
	.MuiDrawer-docked .MuiPaper-elevation1 {
		box-shadow: unset;
	}
	.MuiBottomNavigationAction-root {
		color: #73757a;
		max-width: 155px;
	}
	.MuiBottomNavigationAction-label {
		font-size: 14px;
	}
	.MuiPaper-elevation4 {
		box-shadow: unset;
	}
	.MuiCardContent-root:last-child {
		padding-left: 56px;
	}

	.bar {
		line-height: 62px;
		height: 62px;
		margin-right: 50px;
		//min-width: 95px;
		text-align: center;
	}
	.bar.BarSelected {
		border-bottom: 2px solid #5056e7;
	}
	.ctag {
		border-radius: 4px;
		max-height: 20px;

		text-transform: uppercase;
		font-weight: 500;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
	}
	.ctag.purple {
		color: #5056e7;
		background: #eeeffd;
	}
	.ctag.green {
		color: #33cd71;
		background: #ebfaf1;
	}
	.ctag.blue {
		color: #3e94f9;
		background: #ecf5ff;
	}
	.ctag.gray {
		color: #73757a;
		background: #f2f5f8;
	}
	.ctag.red {
		color: #f04362;
		background: #feedf0;
	}
	.searchInputWrapper,
	.sortWrapper {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
	.MuiCheckbox-colorPrimary.Mui-checked {
		color: #5056e7;
	}

	.marginForm {
		margin-left: 10px;
		margin-top: 4px;
	}

	.searchInput {
		/*width: 920px !important;*/
	}

	.sortWrapperTrading {
		width: 1129px !important;
	}

	.searchInput,
	.sortWrapper {
		width: 1200px;
		margin: auto;
		max-width: 100%;
		box-shadow: 0px 4px 16px rgba(6, 7, 23, 0.04);
		border-radius: 8px;
		background: white;
	}
	.sortWrapper {
		margin-bottom: 2px;
		display: flex;
		box-shadow: unset;
		background: transparent;
	}
	.sortMargin {
		margin-left: 93.8%;
	}

	.expandSearch {
		text-align: center;
		height: 90px;
		p {
			cursor: pointer;
			width: 100px;
			margin: auto;
			margin-top: 20px;
		}
	}

	.pseudoborder {
		height: 100px;
		background: #f2f5f8;
		position: absolute;
		margin-top: -80px;
		margin-left: -35px;
		width: 1px;
	}

	.secondgrid {
		border-left: 1px solid #f2f5f8;
	}

	.searchInputWrapper .MuiOutlinedInput-notchedOutline {
		border: 1px solid #f2f5f8;
		box-sizing: border-box;
		border-radius: 8px;
	}
	.mailInput .MuiOutlinedInput-root {
		margin-top: 10px;
		margin-bottom: 10px;
		max-height: 40px;
		//padding: 6px 0px 0px 0px;
	}
	.signInInput .MuiOutlinedInput-root {
		margin-top: 0px;
		margin-bottom: 20px;
		max-height: 40px;
		//padding: 6px 0px 0px 0px;
	}
	.makeStyles-menuButton-4 {
		/* margin-right: 16px; */
		position: absolute;
		right: 2px;
	}
	.sortWrapper p {
		margin-top: 7px;
	}
	.Logo {
		padding: 0px;
	}
	.pseudoMailCard {
		margin-top: 16px;
		background: #ffffff;
		border: 1px solid #f2f5f8;
		box-sizing: border-box;
		box-shadow: 0px 4px 16px rgba(6, 7, 23, 0.04);
		border-radius: 8px;
		padding: 20px 20px;
		margin: auto;
		margin-bottom: 20px;
		text-align: center;
		max-width: 1200px;
	}
	.toFullLength {
		min-width: 100%;
	}
	.MuiListItem-button:hover {
		text-decoration: none;
		background-color: #f2f5f8;
	}
	.MuiFormControlLabel-labelPlacementStart {
		width: 100%;
	}
	.noPadding .MuiMenuItem-root {
		padding: 0px;
	}
	.MuiListItemText-root .MuiTypography-root .MuiFormControlLabel-label {
		position: absolute;
		left: 12px;
	}
	.bigField {
		color: #b9babe;
		min-width: 230px;
	}
	.filterMargin {
		display: none;
	}
	.mobMenu {
		font-size: 14px;
	}
	//web only
	@media only screen and (min-width: 1145px) {
		.mobMenu {
			display: flex;
			width: 100%;
		}
		.mobMenuEmbed {
			display: flex;
			width: 100%;
		}

		.hideWeb {
			display: none;
		}
		.MuiDrawer-paperAnchorDockedLeft {
			border-right: unset;
			background: transparent;
			margin-left: 10px;
			margin-top: 32px;
		}
		.pseudoMailCard {
			text-align: left;
		}
		.pseudoMailCard button {
			max-width: 92px;
			min-width: unset;
		}
		.MuiFormControlLabel-label.MuiTypography-body1 {
			max-width: 130px;
		}
		.spacer {
			margin-top: 100px;
		}
	}
	@media screen and (max-width: 444px) {
		//mobile only
		#mc-embedded-subscribe-form .lastBtn {
			margin-right: 5px !important;
		}

		.footer__disclaimer {
			margin: 45px auto !important;
		}
		.expansionIcon {
			left: 83%;
		}
		.homeBtn.one {
			margin-bottom: 60px;
		}
		.mobileDifGrid1 {
			font-size: 32px;
			margin-bottom: 0px;
		}
		#root > div > div:nth-child(1) > div > div:nth-child(1) > div > p {
			font-size: 16px;
			margin-right: 30px;
			margin-left: 30px;
		}
		.bar.BarSelected {
			border-bottom: unset;
			border-left: 2px solid #5056e7;
			margin-left: -10px;
			padding-left: 10px;
		}
		.mainCard {
			.MuiGrid-item {
				//min-width: 100%;
				min-width: inherit;
				padding-left: 16px;
				padding-right: 16px;
			}
			.MuiTypography-root {
				margin-left: 0px !important;
			}
			.MuiCardHeader-root {
				padding-left: 0px;
			}
			.MuiCardContent-root {
				padding: 0px;
			}
			.ctag {
				margin-right: 8px;
			}
			.MuiGrid-grid-xs-5 {
				max-width: 100% !important;
    			flex-basis: 100% !important;
			}
			.MuiGrid-grid-xs-3 {
				max-width: 50% !important;
    			flex-basis: 50% !important;
			}
		}
		.mobMenu {
			display: none;
			flex-direction: column;
			position: absolute;
			background: white;
			width: 100%;
			top: 50px;
			height: auto;
			height: 800px;
			left: 0;
			padding-left: 10px;
			//padding-top: 60%;
		}
		#InvestopediaItem {
			min-width: 50%;
		}
	}
	@media screen and (max-width: 444px) {
		#MuiGrid-item-Mobile .MuiGrid-item {
			min-width: inherit !important;
		}
		#MuiGrid-item-ThirdGrid .MuiGrid-item {
			padding-left: 0px !important;
		}
		#MuiGrid-item-Mobile .pseudoborder {
			left: -10px !important;
		}
	}
	@media screen and (max-width: 750px) {
		#MuiGrid-item-Mobile {
			.MuiGrid-item {
				min-width: inherit !important;
				padding-left: 16px !important;
				padding-right: 16px !important;
			}
			.MuiTypography-root {
				margin-left: 0px !important;
			}
			.MuiCardHeader-root {
				padding-left: 0px;
			}
			.MuiCardContent-root {
				padding: 0px;
			}
			.ctag {
				margin-right: 8px;
			}
		}
		#MuiGrid-item-ThirdGrid .MuiGrid-item {
			padding-left: 0px !important;
		}
		#MuiGrid-item-Mobile .pseudoborder {
			left: -10px !important;
		}
	}
	//phones and tablets
	@media only screen and (max-width: 1145px) {
		.transparentFixedHeader {
			.mobMenu {
				background: rgba(80, 86, 231, 0.84);
				margin-top: 6px;
			}
			.logo.hideWeb {
				filter: brightness(5.5);
			}
			.MuiGrid-grid-xs-6 {
				min-width: 100%;
				margin-left: auto;
				margin-right: auto;
				img {
					max-width: 100%;
				}

				.textGrid1,
				.textGrid2 {
					text-align: center;
				}
			}

			.reverse {
				flex-flow: column-reverse;
			}
			.front {
				flex-flow: column;
			}
			form {
				max-width: 300px;
				margin-left: auto;
				margin-right: auto;
			}
			#mc-embedded-subscribe-form {
				flex-direction: column;
				button {
					min-width: 93%;
					margin-right: 30px;
					margin-top: 10px;
				}
			}
		}
		.MuiOutlinedInput-adornedStart {
			min-width: 100%;
		}
		.mobMenu {
			display: none;
		}
		.mobMenuEmbed {
			display: flex;
			flex-direction: row;
			position: relative;
			background: white;
			width: 100%;

			padding-left: 10px;
		}
		.leftNav {
			display: none;
		}
		.respCont {
			display: unset;
		}
		.sortMargin {
			margin-left: 82%;
		}
		.filterMargin {
			margin-left: 48%;
			position: absolute;
			display: flex;
		}
		.filterMargin img,
		.sortMargin svg {
			margin-right: -7px;
		}
		.ctag {
			margin-bottom: 8px;
			margin-right: 40px;
		}
		.bigField {
			color: '#B9BABE';
			min-width: 100%;
		}
		.hideMob {
			display: none;
		}
		.footerFx {
			display: block;
			max-width: 80%;
			min-width: 80%;
			margin: auto;
			text-align: center;
		}
		.transparentFixedHeader h1 {
			font-family: Red Hat Display;
			font-style: normal;
			font-weight: normal;
			font-size: 34px;
			color: #ffffff;
		}
		.transparentFixedHeader .textGrid1,
		.transparentFixedHeader .textGrid2 {
			/*  margin-top: -100px; */
			padding-left: 5px;
			padding-right: 5px;
		}
		.transparentFixedHeader {
			.textGrid2 {
				margin-top: 20px;
			}
			div.MuiGrid-root.imgGrid1.exceptionImg.MuiGrid-item.MuiGrid-grid-xs-6 > img {
				max-width: 130%;
				margin: auto;
				margin-left: -80px;
				margin-bottom: -20px;
				object-fit: unset;
			}
			.imgLimit {
				margin-top: 25px;
				max-width: 130%;
				min-width: 100%;
				/* margin: auto; */
				/* margin-left: -80px; */
				/* margin-bottom: -20px; */
			}
		}
		.transparentFixedHeader .textGrid2.marginsx,
		.marginsx {
			margin-top: -120px;
		}
		.homeBtn.alt {
			margin: auto;
		}
	}

	@media only screen and (max-width: 1470px) {
		.ctag {
			margin-bottom: 8px;
			margin-right: 150px;
		}
	}

	.altFooter .footerFx span span {
		color: white;
		background: transparent;
	}
	div
		> div.altFooter
		> div
		> button.MuiButtonBase-root.MuiBottomNavigationAction-root.bigField
		> span.MuiBottomNavigationAction-wrapper
		> span {
		color: #dedede !important;
	}

	.makeStyles-content-7,
	.mainPrinc {
		padding: 10px;
	}
	.thirdgrid {
		padding-bottom: 10px;
	}
}

//outside root

.MuiIconButton-colorPrimary {
	color: #5056e7;
	position: absolute;
	right: 0px;
}
.MuiFormGroup-root {
	min-width: 237px;
}

.terms {
	&__title {
		color: #3c4043;
		font-family: 'Google Sans', sans-serif;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		margin: 0;
		padding: 64px 0 0 0;
	}

	&__title:first-child {
		color: #3c4043;
		font-family: 'Google Sans', sans-serif;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		margin: 0;
		padding: 0 0 0 0;
	}
}

.footer__disclaimer {
	text-align: left !important;
	color: #7d7f9c;
	margin: 20px auto !important;
	font-size: 13px;
	word-spacing: 4px;
	line-height: 20px;

	p {
		padding: 0 10px;
	}
}
//custom sizes
@media only screen and (min-width: 440px) and (max-width: 1145px) {
	#thisdoc #bodyId .transparentFixedHeader .homeBtn.alt {
		margin-bottom: 130px;
	}
	#mc-embedded-subscribe-form > div:nth-child(2) {
		height: 55px;
	}
	#thisdoc #bodyId .mobMenu {
		display: none;
		margin-left: 40px;
	}
	#thisdoc #bodyId .showTablet {
		display: initial;
		margin-top: 20px;
	}
}
@media only screen and (min-width: 770px) and (max-width: 1494px) {
	.footerApp {
		margin-left: 16%;
	}
	.makeStyles-content-7,
	.mainPrinc {
		max-width: 95%;
		margin: auto;
	}
}

#Investopedia_CardHeader {
	padding: 10px 10px 0px 16px !important;
}

@media only screen and (max-width: 444px) {
	#Investopedia_CardHeader {
		padding: 16px 16px 16px 0px !important;
	}
}

@media only screen and (max-width: 400px) {
	.Logo {
		margin-right: 0px !important;
	}
}

.preview_Iframe::-webkit-scrollbar {
	display: none;
  }
.preview_Iframe {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

/* Modal images voew style START */
.modal {
	display: none;
	position: fixed;
	z-index: 10001;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.9);
}
.modal-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
}
.modal-content {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
	from {-webkit-transform:scale(0)}
	to {-webkit-transform:scale(1)}
}
@keyframes zoom {
	from {transform:scale(0)}
	to {transform:scale(1)}
}
@-webkit-keyframes close {
	from {-webkit-transform:scale(1)}
	to {-webkit-transform:scale(0)}
}
@keyframes close {
	from {transform:scale(1)}
	to {transform:scale(0)}
}
@-webkit-keyframes sticky {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}
@-moz-keyframes sticky {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}
@-o-keyframes sticky {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes sticky {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}
.closeModal {
	top: 0;
	right: 0;
}
.leftModal:hover,
.leftModal:focus,
.rightModal:hover,
.rightModal:focus,
.closeModal:hover,
.closeModal:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}
.leftModal {
	top: 50%;
	left: 0;
}
.rightModal {
	top: 50%;
	right: 0;
}
.iconModal {
	position: fixed;
	color: #f1f1f1;
	font-size: 30px;
	font-weight: bold;
	transition: 0.3s;
	height: 50px;
    width: 50px;
    background-color: RGB(0,0,0,0.5);
    border-radius: 100px;
    display: flex;
    justify-content: center;
	align-items: center;
	margin: 4px;
}
@media only screen and (max-width: 700px){
	.modal-content {
	  width: 100%;
	}
}
/* Modal images voew style END */

.recommendation .MuiOutlinedInput-input {
	padding: 8px 14px !important;
}

.recommendationInput .MuiOutlinedInput-root {
	margin-top: 0px;
	margin-bottom: 0px;
	max-height: 48px;;
}

/* Sticky menu style START */
.stickyButton {
	position: fixed;
	top: 70px;
	left: 0;
	width: 100%;
	background: white;
	z-index: 1099;
	padding: 2px 21px 0 21px;
	-webkit-animation-name: sticky;
	-webkit-animation-duration: 1s;
	animation-name: sticky;
	animation-duration: 1s;
}
.stickyTabs {
	position: fixed;
	top: 108px;
	left: 0;
	width: 100%;
	background: white;
	z-index: 1099;
	padding-left: 11px;
	-webkit-animation-name: sticky;
	-webkit-animation-duration: 1s;
	animation-name: sticky;
	animation-duration: 1s;
}
.stickyButtonModal {
	position: fixed;
	top: 0px;
	left: 0;
	width: 100%;
	background: white;
	z-index: 1099;
	padding: 10px 21px 0 21px;
	-webkit-animation-name: sticky;
	-webkit-animation-duration: 1s;
	animation-name: sticky;
	animation-duration: 1s;
}
.stickyTabsModal {
	position: fixed;
	top: 46px;
	left: 0;
	width: 100%;
	background: white;
	z-index: 1099;
	padding-left: 11px;
	-webkit-animation-name: sticky;
	-webkit-animation-duration: 1s;
	animation-name: sticky;
	animation-duration: 1s;
}
/* Sticky menu style END */

#dialog_wizard .MuiDialog-paperWidthSm {
	max-width: 800px !important;
}
.selected-placeholder {
	opacity: 0.4;
}

/* Animation widget header START */
:root {
    --delay: 0;
    --duration: 800ms;
    --iterations: 1;
}
.reveal-text,
.reveal-text::after {
    -webkit-animation-delay: var(--animation-delay, 2s);
            animation-delay: var(--animation-delay, 2s);
    -webkit-animation-iteration-count: var(--iterations, 1);
            animation-iteration-count: var(--iterations, 1);
    -webkit-animation-duration: var(--duration, 800ms);
            animation-duration: var(--duration, 800ms);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}
.reveal-text {
    --animation-delay: var(--delay, 0);
    --animation-duration: var(--duration, 800ms);
    --animation-iterations: var(--iterations, 1);
    position: relative;
    font-size: 10vw;
    -webkit-animation-name: clip-text;
            animation-name: clip-text;
    color: #FFF;
    white-space: nowrap;
    cursor: default
}
.reveal-text::after {
	content: "";
	position: absolute;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #5056E7;
	-webkit-transform: scaleX(0);
			transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
			transform-origin: 0 50%;
	pointer-events: none;
	-webkit-animation-name: text-revealer;
			animation-name: text-revealer;
}
@-webkit-keyframes clip-text {
    from {
        -webkit-clip-path: inset(0 100% 0 0);
                clip-path: inset(0 100% 0 0);
    }
    to {
        -webkit-clip-path: inset(0 0 0 0);
                clip-path: inset(0 0 0 0);
    }
}
@keyframes clip-text {
    from {
        -webkit-clip-path: inset(0 100% 0 0);
                clip-path: inset(0 100% 0 0);
    }
    to {
        -webkit-clip-path: inset(0 0 0 0);
                clip-path: inset(0 0 0 0);
    }
}
@-webkit-keyframes text-revealer {
    0%, 50% {
        -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
    }
    60%, 100% {
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
    }
    60% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }
    100% {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }
}
@keyframes text-revealer {
    0%, 50% {
        -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
    }
    60%, 100% {
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
    }
    60% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }
    100% {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }
}
/* Animation widget header END */

#combo-box-autocomplete {
	padding-left: 56px !important;
}
#combo-box-autocomplete-container .MuiOutlinedInput-notchedOutline {
	border: 1px solid #F2F5F8 !important;
	box-sizing: border-box !important;
	border-radius: 8px !important;
}

/* tag autocomplete START */
.tribute-container {
	position: absolute;
	top: 0;
	left: 0;
	height: auto;
	//max-height: 300px;
	//max-width: 500px;
	overflow: auto;
	display: block;
	z-index: 999999;
	background: #FFFFFF;
	border: 1px solid #F2F5F8;
	box-sizing: border-box;
	border-radius: 8px;

	.has-background-primary {
		background: #ddd;
	}
}
.tribute-container ul {
	margin: 0;
	margin-top: 2px;
	padding: 0;
	list-style: none;
	background: #FFFFFF;
}
.tribute-container li {
	cursor: pointer;
	div {
		padding: 10px;
	}
}
.tribute-container li.highlight, .tribute-container li:hover {
	background: #ddd;
}
.tribute-container li span {
	font-weight: bold;
}
.tribute-container li.no-match {
	cursor: default;
	padding: 10px;
}
.tribute-container .menu-highlighted {
	font-weight: bold;
}
/* tag autocomplete END */

/* Stream START */
.stream-post-active {
	background: #fafbfe;;
}
.stickyButtonStream {
	position: fixed;
	top: 92px;
	left: 0;
	width: 100%;
	z-index: 1099;
	padding: 0px 21px 0 21px;
	-webkit-animation-name: sticky;
	-webkit-animation-duration: 1s;
	animation-name: sticky;
	animation-duration: 1s;
}
/* Stream END */

/* DeepDive START */
.prosCons {
	ul {
		padding: 0 10px 0 28px;
		list-style: none;
	}
	ul li::before {
		content: "\2022";
		color: #73757A;
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
	  }
}
.deepDiveBullet {
	ul {
		padding: 0 0 0 28px;
		list-style: none;
	}
	ul li::before {
		content: "\2022";
		color: #73757A;
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
	li {
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #30333E;
		margin-bottom: 16px;
	}
}

#deepDiveRow {
	.ctag {
		margin-right: 8px !important;
	}
}

a.deepDiveLink:link,
a.deepDiveLink:visited,
a.deepDiveLink:hover,
a.deepDiveLink:active {
	color: #5056E7;
	text-decoration: none;
	cursor: pointer;
}

.prosConsHelpTitle {
	font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 700px) {
	.MuiGrid-item-Mobile-deepDive.MuiGrid-container {
		display: block !important;
	}
	.MuiGrid-item-Mobile-deepDive .MuiGrid-grid-sm-6 {
		max-width: 100% !important;
	}
	.MuiGrid-item-Mobile-deepDive .MuiGrid-grid-xs-6 {
		max-width: 100% !important;
	}
}
/* DeepDive END */

/* Animate Custom Slider STAR */
/*.CustomSliderElement .MuiSlider-thumb:not(.MuiSlider-active) {
	transition: left 1s ease-in;
}
.CustomSliderElement .MuiSlider-track {
	transition: width 1s ease-in;
}*/
/* Animate Custom Slider FINISH */

/* QA STAR */
.qaBullet {
	ul {
		padding: 0 0 0 28px;
		list-style: none;
	}
	ul li::before {
		content: "\2022";
		color: #5056E7 !important;
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
	li {
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 30px;
		color: #30333E;
		margin-bottom: 16px;
	}
}

a.qaLink:link,
a.qaLink:visited,
a.qaLink:hover,
a.qaLink:active {
	color: #5056E7;
	text-decoration: none;
	cursor: pointer;
}

.Btn,
.Btn:hover {
	max-width: 213px;
	background: white;
	color: #5056e7;
	height: 48px;
	font-size: 16px;
}
.Btn.alt {
	background: #5056e7;
	color: white;
}
.bgSize {
	background-size: cover !important;
}
/* QA FINISH */

/* Horizontal Click and Drag Scrolling START */
.scrollItems {
    user-select: none;
    cursor: pointer;
  }
  .scrollItems.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
/* Horizontal Click and Drag Scrolling FINISH */